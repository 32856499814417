/* Full-page styling */
.forgot-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
  padding: 20px;
}

/* Box containing the content */
.forgot-password-box {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.typing-text {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Courier New", Courier, monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid;
  width: 100%;
  margin: 0 auto;
  animation: typing 3s steps(20) 1 normal both, blink 0.75s step-end infinite;
}

/* Typing effect keyframes */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/* Smooth fade-in effect for the paragraph */
.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Cool button with hover animation */
.animated-button {
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.animated-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transition: left 0.3s ease;
}

.animated-button:hover:before {
  left: 100%;
}

.animated-button:hover {
  background-color: #0056b3;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Coffee image animation */
.coffee-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: bounceIn 1s ease-in-out forwards;
}

.coffee-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.coffee-message {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #333;
}

/* Coffee bounce animation */
@keyframes bounceIn {
  0% {
    transform: translateY(-100px) scale(0);
  }
  60% {
    transform: translateY(20px) scale(1.2);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

/* Responsive Design */

@media screen and (max-width: 768px) {
  .forgot-password-box {
    padding: 30px;
  }

  .typing-text {
    font-size: 2rem;
  }

  .animated-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .coffee-image {
    width: 80px;
    height: 80px;
  }

  .coffee-message {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .forgot-password-box {
    padding: 20px;
  }

  .typing-text {
    font-size: 1.5rem;
  }

  .animated-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .coffee-image {
    width: 60px;
    height: 60px;
  }

  .coffee-message {
    font-size: 1rem;
  }
}
