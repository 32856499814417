/* General Style for the dropdown button */
.status-dropdown {
  padding: 10px 20px 10px 12px; /* Increase right padding to make room for the arrow */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px; /* Slightly larger font for better readability */
  font-family: "Arial", sans-serif; /* Simple and elegant font */
  color: #333;
  appearance: none; /* Removes default browser styling */

  /* Custom dropdown arrow using SVG */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 6px center; /* Move the arrow to the right */
  background-size: 10px; /* Adjust arrow size */

  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

/* Style for when dropdown is focused */
.status-dropdown:focus {
  outline: none;
  border-color: #4c9aff;
  box-shadow: 0 0 0 3px rgba(76, 154, 255, 0.3);
}

/* Style for when dropdown is hovered */
.status-dropdown:hover {
  border-color: #b3b3b3;
}

/* Specific color for Pending (red) */
.status-pending {
  background-color: #ffcccc; /* Light red background */
  color: #e60000; /* Red text */
  border-color: #e60000; /* Red border */
}

/* Specific color for Completed (green) */
.status-completed {
  background-color: #ccffcc; /* Light green background */
  color: #009933; /* Green text */
  border-color: #009933; /* Green border */
}

/* Style dropdown options */
.status-dropdown option {
  padding: 8px;
  font-size: 16px; /* Match the dropdown font size */
  font-family: "Arial", sans-serif; /* Keep dropdown options font consistent */
  color: #333; /* Keep options text consistent */
}

/* Add a border-bottom to each table row */
.table-row {
  border-bottom: 1px solid #ddd;
}

/* Optional: Style the table headers */
.orderTable th {
  padding: 10px;
  text-align: left;
  font-family: "Arial", sans-serif;
}

/* Optional: Style the table data cells */
.orderTable td {
  padding: 10px;
}

/* Font styling specifically for the status column */
.status-column {
  font-family: "Arial", sans-serif; /* Clean and simple font */
  font-size: 16px; /* Slightly larger font size for clarity */
  font-weight: 500; /* Medium weight for modern look */
  color: #333; /* Consistent color for the status */
}
